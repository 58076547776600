import React, { useEffect, useState } from 'react'
import { Card, Col, Container } from 'react-bootstrap'
import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { LineWave } from 'react-loader-spinner';
import 'moment-timezone'
import KWAdminNavbar from '../../components/navbar/AdminNav'
import config from '../../config.json'
import { useNavigate } from 'react-router-dom';
Moment.globalTimezone = 'America/Toronto'

const ListCalenderEvent = () => {
    const [isLoading, setIsloading] = useState(true)
    const [itemData, setItemData] = useState({});
    const navigate = useNavigate();

    const fetchData = () => {
        axios.get(config.apiUrl + '/v1/calender/events').then((res) => {
            return res.data.groupedEvents;
        }).then((data) => {
            setItemData(data)
            setIsloading(false)
        }).catch(() => {
            setIsloading(false)
        })
    };

    useEffect(() => {
        const isLogin = sessionStorage.getItem('isLogin')
        if (isLogin != 'true') {
            navigate('/admin')
        }
        fetchData()
    }, []);

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return (
        <>
            <KWAdminNavbar />
            <Container className='pt-5'>
                <div className='form-content'>
                    <Col className='mx-auto' sm={12} lg={12}>
                        <Card className='pt-3 border-0 minheight form-card mt-4'>
                            <h4 className='text-center m-2'>Calender Events List</h4>
                            <div className="col-sm-12">
                                {
                                    isLoading !== true ?
                                        itemData ?
                                            Object.keys(itemData)?.map((key) => {
                                                console.log(itemData[key])
                                                const cDay = new Date(key)
                                                cDay.toLocaleString('en-US', { timeZone: 'America/Toronto' })
                                                let d = new Date();
                                                d.toLocaleString('en-US', { timeZone: 'America/Toronto' })
                                                const today = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                                                const keyDate = key.split('-').reduce((a, b) => Number(a) + Number(b),)
                                                const td = today.split('-').reduce((a, b) => Number(a) + Number(b),)
                                                const title = td === keyDate ? 'Today' : `${daysOfWeek[cDay.getDay()]} , ${months[cDay.getMonth('m')]} ${cDay.getDate()}  ${cDay.getFullYear()}`


                                                const timeZone = 'America/Toronto';

                                                const formattedDate = moment.tz(key, timeZone);

                                                const isToday = moment().isSame(formattedDate, 'day');
                                                const isTomorrow = moment().add(1, 'day').isSame(formattedDate, 'day');

                                                let displayDate;
                                                if (isToday) {
                                                    displayDate = 'Today';
                                                } else if (isTomorrow) {
                                                    displayDate = 'Tomorrow';
                                                } else {
                                                    displayDate = formattedDate.format('MMM DD, YYYY');
                                                }

                                                return (
                                                    <>
                                                        <div className="secound-content mt-1">
                                                            <div className="content2-head">{displayDate}</div>
                                                            <div className="content2-border"></div>
                                                            <div className="content2-row">
                                                                {
                                                                    itemData[key].map((item) => {
                                                                        if (item.details !== undefined) {
                                                                            const labl = new Date(item.start.dateTime);
                                                                            labl.toLocaleString('en-US', { timeZone: item.start.timeZone })

                                                                            return (
                                                                                <div className="row mt-2">
                                                                                    <div className="col-sm-12 col-md-2 time">
                                                                                        <Moment date={item.start.dateTime} format="hh:mm a" />
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-4">
                                                                                        <p
                                                                                            style={{ cursor: 'pointer', marginBottom: '0px', }}
                                                                                            onClick={() => navigate(`/listing/${item.extendedProperties.shared.propertyId}`)} className='middle-link1'>{item.details.summery}</p>
                                                                                        <small style={{ color: "#999998", marginTop: '-5px' }} className='d-block p-0'>{item.description}</small>
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-4">
                                                                                        <div className='w-100 d-flex align-items-center'>
                                                                                            <div className='me-2'>Status : </div>
                                                                                            <div>
                                                                                                {
                                                                                                    item.details.meetingStatus === "c" ? (
                                                                                                        <span className='text-danger'>CANCELLED</span>
                                                                                                    ) : (
                                                                                                        <span className='text-success'>OPEN</span>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-12 col-md-2">
                                                                                        <button
                                                                                            onClick={() => navigate(`/admin/update-event/${item.id}`, { state: itemData[key] })}
                                                                                            className='book-Showing-c btn btn-warning'>UPDATE</button>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                            : ('')
                                        : (
                                            <div className="d-flex justify-content-center">
                                                <LineWave
                                                    height="200px"
                                                    width="300px"
                                                    color="#0586f0"
                                                    ariaLabel="line-wave"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        )
                                }
                                 {
                                    !isLoading && (
                                        Object.keys(itemData).length === 0 && (
                                            <>
                                            <div style={{minHeight:'40vh'}} className="d-flex text-danger align-content-center justify-content-center">No Events Found!</div>
                                            </>
                                        )
                                    )
                                }
                            </div>
                        </Card>
                    </Col>
                </div>
            </Container >
        </>
    )
}

export default ListCalenderEvent
