import React from 'react'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'

const Listings = () => {
    return (
        <>
            <KWNavbar />
            <br />
            <br />
            <iframe src="https://ext.leasey.ai/listings/sublet-it?fram=1" width="100%" height="100vh" frameborder="0"
                style={{
                    border: '0px',
                    overflow: 'hidden',
                    height:'100vh'
                }}> </iframe>
            <KWFooter />
        </>
    )
}

export default Listings
