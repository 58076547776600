import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Button, Container, InputGroup, Col, Card } from 'react-bootstrap';
import { FaUser, FaLock } from 'react-icons/fa'; // Import specific icons
import config from '../../config.json'
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import './style.css'

function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            axios.post(`${config.apiUrl}/v1/admin/login`, {
                user: email,
                password: password
            }).then(res => {
                setIsLoading(false)
                sessionStorage.setItem('isLogin', 'true')
                navigate('/admin/dashboard')
            }).catch((err) => {
                setIsLoading(false)
                swal('', err.message, 'error')
            })
        } catch (error) {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        const isLogin = sessionStorage.getItem('isLogin')
        if (isLogin == 'true') navigate('/admin/dashboard');
    }, [])

    return (
        <Container className="pt-5">
            <div className='form-content'>
                <Col className='mx-auto' sm={12} lg={4}>
                    <Card className='pt-3 border-1 p-4 form-card mt-4'>
                        <h2 className="text-center mb-4">Login</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>User name</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FaUser />
                                    </InputGroup.Text>
                                    <Form.Control
                                        style={{ height: '100%' }}
                                        type="text"
                                        placeholder="Enter user name"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword" className="mt-3">
                                <Form.Label>Password</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FaLock />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group controlId="formBasicCheckbox" className="mt-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Remember me"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="mt-3 w-100">
                                {!isLoading ? "Login" : <Spinner size='sm' animation='border' variant='info' />}
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </div>
        </Container>
    );
}

export default LoginForm;
