import React from 'react'
import KWAdminNavbar from '../../components/navbar/AdminNav'
import "./style.css"

const AdminHome = () => {
    return (
        <>
            <KWAdminNavbar />
            <div className='dash-board'>
                <h4>Welcome to dashboard</h4>
            </div>
        </>
    )
}

export default AdminHome
