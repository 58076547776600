import React, { useState } from 'react'
import './navbar.css'
import logo from '../../assets/logo_blue.png'
import { Container, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaBars, FaChevronDown, FaUserCircle } from 'react-icons/fa';
const KWAdminNavbar = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [showCalenderAct, setCalenderAtc] = useState(false)
    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        window.location.replace('/admin');
    };

    const toggleCalenderLink = () => {
        setCalenderAtc(!showCalenderAct)
    }

    return (
        <>
            <Navbar bg="light" variant="light" className='fixed-top navbar-styles'>
                <Container>
                    <Navbar.Brand>
                        <Nav.Link as={Link} to="/">
                            <img
                                src={logo}
                                width="135"
                                className="d-inline-block align-top"
                                alt="KW Property logo"
                            />
                        </Nav.Link>
                    </Navbar.Brand>
                    <FaBars size={26} color='#222' onClick={toggleSidebar} className='ms-2 d-xl-none' />
                    <Nav className='d-none d-xl-flex'>
                        <Nav.Link
                            variant="link"
                            className={`nav-link ${showCalenderAct ? 'highlight-link' : ''}`}
                            onClick={toggleCalenderLink}
                            aria-controls="properties-links"
                            aria-expanded={showCalenderAct}
                        >Calender<span><FaChevronDown className='ms-1' size={12} /></span></Nav.Link>
                        <NavDropdown
                            title={
                                <span>
                                    <FaUserCircle size={20} />
                                </span>
                            }
                            id="nav-dropdown"
                            align="end"
                        >
                            <NavDropdown.Item href="#profile">Profile</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                        </NavDropdown>
                        {showCalenderAct && (
                            <div className="properties-links ps-4 p-2 d-flex justify-content-center bg-light drop-links">
                                <Nav.Link to={'/admin/add-event'} as={Link} style={{ color: '#033e8a' }} className='nav-link' >Add Event</Nav.Link>
                                <Nav.Link to={'/admin/event-list'} as={Link} style={{ color: '#033e8a' }} className='nav-link' >List Events</Nav.Link>
                            </div>
                        )}
                    </Nav>

                </Container>
            </Navbar>

            <Offcanvas show={showSidebar} onHide={toggleSidebar} placement="start" className="full-screen">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title> <img
                        src={logo}
                        width="100"
                        alt="KW Property logo"
                    /></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="container d-flex flex-column align-items-start">
                        <Nav.Link as={Link} to="/admin/add-event">
                            Add event
                        </Nav.Link>
                        <Nav.Link as={Link} to="/admin/event-list">
                            List events
                        </Nav.Link>
                        <NavDropdown title="Profile" id="nav-dropdown">
                            <NavDropdown.Item eventKey="4.1">Profile</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleLogout} eventKey="4.2">Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}


export default KWAdminNavbar