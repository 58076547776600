import React, { useEffect, useRef, useState } from 'react'
import config from '../../config.json'
import { Button, Card, Col, Container, Form } from 'react-bootstrap'
import axios from 'axios'
import swal from 'sweetalert'
import { LineWave } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import KWAdminNavbar from '../../components/navbar/AdminNav'

const AddEvent = () => {
    const navigate = useNavigate()
    const [isSubmiting, setSubmitting] = useState(false)
    const [property, setProperty] = useState([])
    const formRef = useRef(null)
    const [showingData, setShowingData] = useState({
        description: '',
        summary: '',
        start: {
            dateTime: '',
            timeZone: "America/Los_Angeles"
        },
        end: {
            dateTime: '',
            timeZone: "America/Los_Angeles"
        },
        extendedProperties: {
            shared: {
                propertyId: '',
                agentName: ''
            },
            private: {}
        },
    })
    const handleChange = (event) => {
        let { name, value } = event.target
        if (name == "property") {
            setShowingData(prev => ({ ...prev, extendedProperties: { ...prev.extendedProperties, shared: { ...prev.extendedProperties.shared, propertyId: value } } }))
            setShowingData(prev => ({ ...prev, summary: `[o] ${event.target.selectedOptions[0].innerText}` }))
        } else if (name == "agentName") {
            setShowingData(prev => ({ ...prev, extendedProperties: { ...prev.extendedProperties, shared: { ...prev.extendedProperties.shared, agentName: value } } }))
        } else if (name == 'start' || name == 'end') {
            value = new Date(value).toISOString();
            name != 'end' && setShowingData((prv) => ({ ...prv, start: { ...prv.start, dateTime: value } }))
            name != 'start' && setShowingData((prv) => ({ ...prv, end: { ...prv.start, dateTime: value } }))
        } else {

            setShowingData(prv => {
                return {
                    ...prv,
                    [name]: value
                }
            })
        }


    }
    const handleAddShowing = () => {
        setSubmitting(true)
        axios.post(`${config.apiUrl}/v1/calender/event`, showingData)
            .then(res => {
                if (res.status == 200) {
                    formRef.current.reset()
                    swal("", "Event added", "success")
                        .then(() => setSubmitting(false))
                }
            })
            .catch((err) => {
                setSubmitting(false)
                console.log(err)
            })
    }

    useEffect(() => {
        const isLogin = sessionStorage.getItem('isLogin')
        if (isLogin != 'true') {
            navigate('/admin')
        }
        axios.get(`${config.apiUrl}/v1/listings/kwpweb/listing`)
            .then(data => {
                setProperty(data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <>
            <KWAdminNavbar />
            <Container className='pt-5'>
                <div className='form-content'>
                    <Col className='mx-auto' sm={12} lg={6}>
                        <Card className='pt-3 border-0 form-card mt-4'>
                            <h4 className='text-center'>Add Showing</h4>
                            <Form ref={formRef}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Property</Form.Label>
                                    <Form.Select onChange={handleChange} name='property'>
                                        {property.length > 0 && property.map((p, index) => {
                                            return <option key={index} value={p.Unit.Id}>{p.Property.Name}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Agent Name</Form.Label>
                                    <Form.Control onChange={handleChange} name='agentName' type="text" placeholder="Enter agent name" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Start date</Form.Label>
                                    <Form.Control onChange={handleChange} name='start' type="datetime-local" placeholder="Enter your phone number" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control onChange={handleChange} name='end' type="datetime-local" placeholder="Enter your phone number" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        onChange={handleChange} name='description' rows={3} placeholder="Description" />
                                </Form.Group>

                                <Button onClick={handleAddShowing} className='btn btn-primary mt-2'>
                                    {isSubmiting ? <LineWave
                                        height="20px"
                                        width="70px"
                                        color="#ffffff"
                                        ariaLabel="line-wave"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    /> : "Submit"}
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </div>
            </Container>
        </>
    )
}

export default AddEvent
