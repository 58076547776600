import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./listings.css";
import { useNavigate } from "react-router-dom";

import img from '../../assets/no-image.png'

export const Listings = ({ listing }) => {
  const navigate = useNavigate()
  // console.log(listing)
  return (
    <Container className="p-2 p-md-5">
      <Row className="main-container">
        <Col sm={12} lg={4} className="mx-auto d-block">
          <Image src={listing.Unit.Files?.length ? listing.Unit.Files[0].Url : img} className="image-container mb-4 mb-lg-0" />
        </Col>
        <Col sm={12} lg={8} className="pt-2">
          <h3>{listing.Property.Name}</h3>
          {/* <p>{listing.Property.StructureDescription}</p> */}

          <p className='text-capitalize'>Address: {listing.Property.Address.AddressLine1.toLowerCase()}
          </p>

          <div className="d-flex justify-content-end align-items-end button-container pt-md-5">
            <h5 className="text-primary me-3 price-container">${listing.Rent}/month</h5>
            {/* <Button className="btn btn-primary view-button" onClick={() => {navigate("/listing", {state: {listing}})}}>View</Button> */}
            <Button className="btn btn-primary view-button" onClick={() => { navigate(`/listing/${listing?.Unit?.Id}`) }}>View</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
