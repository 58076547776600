import React, { useState } from 'react';
import './contactus.css';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import config from '../../config.json';
import axios from 'axios';
import swal from 'sweetalert';
import ReCAPTCHA from 'react-google-recaptcha';
import DOMPurify from 'dompurify';

export const Contactus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleCaptcha = (val) => {
    setIsCaptchaVerified(Boolean(val));
  };

  function validateEmail(email) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleSubmit = async () => {
    const sanitizedContactData = {
      name: DOMPurify.sanitize(name),
      email: DOMPurify.sanitize(email),
      phone: DOMPurify.sanitize(phone),
      message: DOMPurify.sanitize(message),
    };

    if (!name) {
      swal("", "Name is required!", "warning");
    } else if (!email) {
      swal("", "Email is required!", "warning");
    } else if (!validateEmail(sanitizedContactData.email)) {
      swal("", "Invalid Email!", "warning");
      return;
    } else if (!phone) {
      swal("", "Phone number is required!", "warning");
    } else if (!message) {
      swal("", "Your message is required!", "warning");
    } else {
      try {
        const res = await axios.post(`${config.apiUrl}/v1/mailsend/contactus`, sanitizedContactData);
        if (res.data.status === 200) {
          swal("Success", "Thanks for contacting us. We will get back to you shortly.", "success");
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
        }
      } catch (error) {
        console.error("There was an error sending the contact form!", error);
        swal("Error", "There was an error sending your message. Please try again later.", "error");
      }
    }
  };

  return (
    <>
      <KWNavbar />
      <Container className='mt-5 pt-5'>
        <Row>
          <Col sm={12} lg={8}>
            <div className='form-content'>
              <h1>Contact <span className='blue'>Us</span></h1>
              <p className='pe-lg-5'>
                With K-W Property Management Corp, we make finding your new home easy!
                Contact us, and we will assist you in finding not only a home but the right home for you.
                Whether you are looking for an apartment, condo, single dwelling home, semi-detached, townhouse,
                executive suite, or even a bachelor apartment, we have plenty of selection to choose from.
                <br /> We will help you find a home that suits the needs and amenities that best fit your lifestyle.
                <br /> If you do not see your ideal home on our website, feel free to contact us and let us know what you are looking for.
                <h6 className='mt-2'>Our <span className=''>Mailing Address:</span> 541 Queen St S. Kitchener, ON N2G 1W9</h6>
                <h6>Phone <span className=''>Number:</span> 519-954-8082</h6>
                <h6>Email <span className=''>Address:</span> kwp@kwproperty.com</h6>
              </p>
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <Card className='p-3 form-card mt-4'>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Enter name"
                    maxLength={50}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter your email"
                    maxLength={100}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    placeholder="Enter your phone number"
                    maxLength={15}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    as="textarea"
                    placeholder="Enter your message"
                    style={{ height: '200px' }}
                    maxLength={500}
                  />
                </Form.Group>
                <div className="mt-2 mb-1" style={{ fontSize: "14px", wordSpacing: "1px" }}>
                  To protect this website from spam, please check the box below.
                </div>
                <ReCAPTCHA
                  sitekey={config.reCaptchaKey}
                  onChange={handleCaptcha}
                />
                <Button
                  onClick={isCaptchaVerified ? handleSubmit : () => swal("!", "Please verify you are not a robot.", "warning")}
                  className='btn btn-primary mt-2'
                >
                  Submit
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <KWFooter />
    </>
  );
};
