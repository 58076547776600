import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Container, Form } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import config from '../../config.json'
import { LineWave } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import swal from 'sweetalert'
import KWAdminNavbar from '../../components/navbar/AdminNav'

const UpdateEvent = () => {
    const navigate = useNavigate();
    const [isSubmiting, setSubmitting] = useState(false)
    const [property, setProperty] = useState([])
    const formRef = useRef(null)
    const [status, setStatus] = useState('')
    const location = useLocation()
    const id = location.pathname.split('/')[location.pathname.split('/').length - 1]

    const [showingData, setShowingData] = useState({
        description: location.state[0].description,
        summary: location.state[0].summary.replace(/[\[a-zA-Z]+\]/, '') || '',
        start: {
            dateTime: location.state[0].start.dateTime || '',
            timeZone: "America/Los_Angeles"
        },
        end: {
            dateTime: location.state[0].end.dateTime || '',
            timeZone: "America/Los_Angeles"
        },
        extendedProperties: {
            shared: {
                propertyId: location.state[0].extendedProperties?.shared.propertyId,
                agentName: location.state[0].extendedProperties?.shared.agentName
            },
            private: {}
        },
    })


    const handleChange = (event) => {
        let { name, value } = event.target
        if (name == "property") {
            setShowingData(prev => ({ ...prev, extendedProperties: { ...prev.extendedProperties, shared: { ...prev.extendedProperties.shared, propertyId: value } } }))
            setShowingData(prev => ({ ...prev, summary: `${event.target.selectedOptions[0].innerText}` }))
        } else if (name == "agentName") {
            setShowingData(prev => ({ ...prev, extendedProperties: { ...prev.extendedProperties, shared: { ...prev.extendedProperties.shared, agentName: value } } }))
        } else if (name == 'start' || name == 'end') {
            value = new Date(value).toISOString();
            name != 'end' && setShowingData((prv) => ({ ...prv, start: { ...prv.start, dateTime: value } }))
            name != 'start' && setShowingData((prv) => ({ ...prv, end: { ...prv.start, dateTime: value } }))
        } else {

            setShowingData(prv => {
                return {
                    ...prv,
                    [name]: value
                }
            })
        }
    }

    useEffect(() => {
        setStatus(location.state[0].summary.match(/[\[a-z]+\]/) || '')
        const isLogin = sessionStorage.getItem('isLogin')
        if (isLogin != 'true') {
            navigate('/admin')
        }

        axios.get(`${config.apiUrl}/v1/listings/kwpweb/listing`)
            .then(data => {
                setProperty(data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    useEffect(() => {
        setShowingData(prev => ({ ...prev, summary: `${status} ${showingData.summary.replace(/[\[a-zA-Z]+\]/, '').trim()}` }))
    }, [status])

    const handleSubmit = (e) => {
        setSubmitting(true)
        // console.log(showingData)
        axios.patch(`${config.apiUrl}/v1/calender/event/${id}`, { ...showingData })
            .then(res => {
                if (res.status == 200) {
                    formRef.current.reset()
                    swal("", "Event update successfully!!", "success")
                        .then(() => {
                            setSubmitting(false)
                            navigate('/admin/event-list')
                        })
                }
            })
            .catch((err) => {
                setSubmitting(false)
                console.log(err)
            })
    }

    return (
        <>
            <KWAdminNavbar />
            <Container className='pt-5'>
                <div className='form-content'>
                    <Col className='mx-auto' sm={12} lg={6}>
                        <Card className='pt-3 border-0 form-card mt-4'>
                            <h4 className='text-center'>Update Event</h4>
                            <Form ref={formRef}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Select value={showingData.extendedProperties?.shared.propertyId} onChange={handleChange} name='description'>
                                        {property.length > 0 && property.map((p, index) => {
                                            return <option key={index} value={p.Unit.Id}>{p.Property.Name}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Agent Name</Form.Label>
                                    <Form.Control value={showingData.extendedProperties.shared.agentName} onChange={handleChange} name='agentName' type="text" placeholder="Enter agent name" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Start date</Form.Label>
                                    <Form.Control
                                        value={moment(showingData.start.dateTime).format("YYYY-MM-DDTHH:mm")}
                                        onChange={handleChange}
                                        name='start'
                                        type="datetime-local" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control
                                        value={moment(showingData.end.dateTime).format("YYYY-MM-DDTHH:mm")}
                                        onChange={handleChange}
                                        name='end'
                                        type="datetime-local" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={showingData.description}
                                        onChange={handleChange} name='description' rows={3} placeholder="Description" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select className={`text-${status == '[o]' ? 'success' : 'danger'}`} value={status} onChange={(e) => setStatus(e.target.value)} name="status">
                                        <option className='text-success' value="[o]">Open</option>
                                        <option className='text-danger' value="[c]">Closed</option>
                                    </Form.Select>
                                </Form.Group>
                                <Button onClick={handleSubmit} className='btn btn-primary mt-2'>
                                    {isSubmiting ? (
                                        <LineWave
                                            height="20px"
                                            width="70px"
                                            color="#ffffff"
                                            ariaLabel="line-wave"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    ) : "Submit"}
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </div>
            </Container>
        </>
    )
}

export default UpdateEvent
